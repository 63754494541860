<template>
  <div class="container relative" data-testid="code-snippet">
    <div class="snippet" v-html="codeHtml" />
    <div class="absolute top-1 right-1 flex flex-row">
      <CopyToClipboardButton :code="code" />
    </div>
  </div>
</template>

<script setup lang="ts">
import CopyToClipboardButton from '@/components/code/CopyToClipboardButton.vue';
import { getShikiHighlight } from '@/api';
// eslint-disable-next-line vue/no-dupe-keys
import { v4 as uuid } from 'uuid';

const props = defineProps({
  uuid: { type: String, default: () => uuid() },
  lang: { type: String, required: true },
  code: { type: String, required: true },
});

const codeHtml = await getShikiHighlight(props.uuid, props.code, props.lang);
</script>

<style lang="scss" scoped>
.snippet {
  :deep(pre) {
    padding: 16px;
    margin: 0;
    overflow: auto;
    @apply rounded-lg;
  }

  :deep(code) {
    text-shadow: 0 1px rgba(0, 0, 0, 0.3);
    font: var(--vvd-typography-base-extended-code);
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;

    -moz-tab-size: 4;
    tab-size: 4;

    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
  }

  :deep(.line) {
    line-height: 1.5;
  }
}
</style>
