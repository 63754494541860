<template>
  <div class="relative">
    <button
      type="button"
      aria-label="Copy To Clipboard"
      @click="copyToClipboard"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    />
    <div v-if="!noTooltip" class="absolute right-0 top-[-200%]" :class="{ hidden: !hover }">
      <div class="bg-black text-white text-xs rounded py-2 px-4 right-0 bottom-full">
        Copy
        <svg
          class="absolute text-black h-2 right-0 mr-3 top-full"
          x="0px"
          y="0px"
          viewBox="0 0 255 255"
          xml:space="preserve"
        >
          <polygon class="fill-current" points="0,0 127.5,127.5 255,0" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const props = defineProps({
  code: { type: String, required: true },
  noTooltip: { type: Boolean, default: false },
});

const copyToClipboard = async () => {
  await navigator.clipboard.writeText(props.code);
};

const hover = ref(false);
</script>

<style lang="scss" scoped>
button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  text-indent: -9999px;
  vertical-align: middle;
  width: 32px;
  height: 32px;

  &:before {
    // light icon
    background-image: url('data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20fill%3D%22%23C2C4CC%22%20d%3D%22M1.5%2018H16.5C17.3295%2018%2018%2017.3295%2018%2016.5V1.5C18%200.672%2017.3295%200%2016.5%200H1.5C0.6705%200%200%200.672%200%201.5V16.5C0%2017.3295%200.6705%2018%201.5%2018Z%22%2F%3E%0A%3Cpath%20fill%3D%22%23C2C4CC%22%20d%3D%22M6%2024H22.5C23.3295%2024%2024%2023.3295%2024%2022.5V6H21V21H6V24Z%22%2F%3E%0A%3C%2Fsvg%3E');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 18px 16px;
    border-radius: 6px;
    box-sizing: inherit;
    content: '';
    display: inline-block;
    padding: 16px;
    position: absolute;
    right: 0;
    text-indent: 0;
    top: 0;
  }

  &:hover {
    color: var(--vvd-color-canvas);
    margin-right: -4px;
    margin-top: -72px;
    text-indent: 0;

    &:before {
      background-color: #616266;
    }
  }

  &:active {
    &:before {
      background-color: var(--vvd-color-canvas);
      // dark icon
      background-image: url('data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20fill%3D%22%23131415%22%20d%3D%22M1.5%2018H16.5C17.3295%2018%2018%2017.3295%2018%2016.5V1.5C18%200.672%2017.3295%200%2016.5%200H1.5C0.6705%200%200%200.672%200%201.5V16.5C0%2017.3295%200.6705%2018%201.5%2018Z%22%2F%3E%0A%3Cpath%20fill%3D%22%23131415%22%20d%3D%22M6%2024H22.5C23.3295%2024%2024%2023.3295%2024%2022.5V6H21V21H6V24Z%22%2F%3E%0A%3C%2Fsvg%3E');
    }
  }
}
</style>
